<script setup>
import { inject, ref, computed, watch, onMounted } from 'vue';
import { useI18n } from 'vue-i18n';
import KalioFormSection from '@/components/form/kalio-form-section.vue';
import KalioFeedbackModal from '@/components/shared/kalio-feedback-modal.vue';
import founderProfilesApi from '@/api/founder_profiles';
import countriesApi from '@/api/countries';
import {
  formatCountriesApiResponse,
  formatStatesApiResponse,
  formatCitiesApiResponse,
} from '@/utils/countries.js';
import { currentUserKey } from '@/utils/keys.js';

const props = defineProps({
  founderProfile: { type: Object, required: true },
  founderProfileForm: { type: Object, required: true },
});

const currentUser = inject(currentUserKey);
const dynamicUser = ref({
  firstName: currentUser.value.firstName,
  lastName: currentUser.value.lastName,
  country: currentUser.value.country,
  dateOfBirth: currentUser.value.dateOfBirth,
  gender: currentUser.value.gender,
  phoneNumber: currentUser.value.phoneNumber,
  socialMedia: { ...currentUser.value.socialMedia },
});

const formAnswers = ref({ ...props.founderProfileForm });

const countries = ref([]);
const states = ref([]);
const cities = ref([]);

function getCountries() {
  countriesApi.getCountries().then(response => {
    countries.value = formatCountriesApiResponse(response);
  });
}

function getStates(code) {
  countriesApi.getStates(code).then(response => {
    states.value = formatStatesApiResponse(response);
  });
}

function getCities(countryCode, stateCode) {
  countriesApi.getCities(countryCode, stateCode).then(response => {
    cities.value = formatCitiesApiResponse(response);
  });
}

const { mergeLocaleMessage } = useI18n();
onMounted(() => {
  getCountries();

  if (dynamicUser.value.country && dynamicUser.value.country.code) {
    getStates(dynamicUser.value.country.code);
  }

  mergeLocaleMessage('es', {
    form: {
      phone: {
        invalid: {
          warning: `No pudimos validar que tu número tenga WhatsApp asociado, entonces
                   solo te avisaremos novedades de tu postulación por correo.`,
        },
      },
    },
  });

  mergeLocaleMessage('en', {
    form: {
      phone: {
        invalid: {
          warning: `We couldn't validate that your number has WhatsApp associated, so
                   we'll only notify you of your application news by email.`,
        },
      },
    },
  });
});

const selectedCountryCode = computed(() => dynamicUser.value.country?.code);
const selectedStateCode = computed(() => formAnswers.value.basicInfo.state?.value?.code);

watch(selectedCountryCode, (code) => getStates(code));
watch(selectedStateCode, (code) => getCities(selectedCountryCode.value, code));

const loading = ref(false);
const errorMessage = ref(null);
function submit() {
  loading.value = true;

  founderProfilesApi.update(props.founderProfile.id, {
    founderProfile: {
      ...props.founderProfile,
      formData: formAnswers.value,
    },
    user: { ...dynamicUser.value },
  })
    .then(() => {
      window.location.href = '/apply?founder_profile_submitted=true';
    })
    .catch(error => {
      errorMessage.value = error.response.data.detail;
    })
    .finally(() => {
      loading.value = false;
    });
}
</script>

<template>
  <div class="flex w-full max-w-screen-md flex-col space-y-7 sm:space-y-10">
    <kalio-back-button href="/apply" />
    <kalio-header
      title="Perfil de fundador"
      icon="profile.svg"
      size="big"
    />
    <vee-form
      class="flex flex-col space-y-8"
      @submit="submit"
    >
      <kalio-form-section title="Información básica">
        <kalio-short-text-input
          v-model="dynamicUser.firstName"
          name="first_name"
          rules="required"
          label="Nombre"
        />
        <kalio-short-text-input
          v-model="dynamicUser.lastName"
          name="last_name"
          rules="required"
          label="Apellido"
        />
        <kalio-select-input
          v-model="dynamicUser.country"
          name="country"
          rules="required"
          label="País"
          :options="countries"
          :get-option-label="(option) => option.name"
        />
        <kalio-select-input
          v-model="formAnswers.basicInfo.state.value"
          name="state"
          :rules="formAnswers.basicInfo.state.rules"
          :label="formAnswers.basicInfo.state.label"
          :options="states"
          :get-option-label="(option) => option.name"
        />
        <kalio-select-input
          v-model="formAnswers.basicInfo.city.value"
          name="city"
          :rules="formAnswers.basicInfo.city.rules"
          :label="formAnswers.basicInfo.city.label"
          :options="cities"
        />
        <base-date-input
          v-model="dynamicUser.dateOfBirth"
          name="date_of_birth"
          rules="required"
          label="Fecha de nacimiento"
          parse-iso
        />
        <kalio-radio-inputs
          v-model="dynamicUser.gender"
          name="gender"
          label="Género"
          :radio-labels="['Masculino', 'Femenino', 'Otro']"
          :values="['male', 'female', 'other']"
          horizontal
        />
        <kalio-tel-input
          v-model="dynamicUser.phoneNumber"
          name="phone"
          label="Teléfono celular"
          :country-hint="dynamicUser.country"
          required
          perform-validation
        />
      </kalio-form-section>

      <kalio-form-section title="Presencia online">
        <kalio-short-text-input
          v-model="formAnswers.presence.personalWebpage.value"
          name="webpage"
          rules="url"
          :label="formAnswers.presence.personalWebpage.label"
        />
        <kalio-short-text-input
          v-model="dynamicUser.socialMedia.github"
          name="github"
          rules="url"
          label="Github"
        />
        <kalio-short-text-input
          v-model="dynamicUser.socialMedia.linkedin"
          name="linkedin"
          rules="url"
          label="Linkedin"
        />
      </kalio-form-section>

      <kalio-form-section title="Experiencia">
        <div class="mb-8 space-y-12">
          <div>
            <kalio-base-input
              :label="formAnswers.experience.degrees.label"
              :description="formAnswers.experience.degrees.description"
            >
              <template #input>
                <div
                  v-for="(degree, i) in formAnswers.experience.degrees.value"
                  :key="degree"
                  class="mt-2 flex items-center space-x-2"
                >
                  <kalio-short-text-input
                    v-model="formAnswers.experience.degrees.value[i].degree"
                    rules="required"
                    :name="`degree-${i}`"
                    :label="i === 0 ? 'Estudio o grado' : ''"
                  />
                  <kalio-short-text-input
                    v-model="formAnswers.experience.degrees.value[i].institution"
                    rules="required"
                    :name="`institution-${i}`"
                    :label="i === 0 ? 'Institución' : ''"
                  />
                  <kalio-button
                    v-if="i > 0"
                    class="mb-px h-11 w-12 shrink-0 self-end bg-black text-blue-gray-50"
                    variant="custom"
                    left-icon="thrash.svg"
                    left-icon-classes="mr-0"
                    type="button"
                    @click="formAnswers.experience.degrees.value.splice(i, 1)"
                  />
                  <div
                    v-else
                    class="w-12 shrink-0"
                  />
                </div>
              </template>
            </kalio-base-input>
            <kalio-button
              type="button"
              label="Agregar estudio o grado"
              class="mt-4 self-start"
              @click="formAnswers.experience.degrees.value.push({ institution: '', degree: '' })"
            />
          </div>
          <div>
            <kalio-base-input
              :label="formAnswers.experience.previousPositions.label"
              :description="formAnswers.experience.previousPositions.description"
            >
              <template #input>
                <div
                  v-for="(previousPosition, i) in formAnswers.experience.previousPositions.value"
                  :key="previousPosition"
                  class="mt-2 flex items-center space-x-2"
                >
                  <kalio-short-text-input
                    v-model="formAnswers.experience.previousPositions.value[i].title"
                    rules="required"
                    :name="`title-${i}`"
                    :label="i === 0 ? 'Título o cargo' : ''"
                  />
                  <kalio-short-text-input
                    v-model="formAnswers.experience.previousPositions.value[i].company"
                    rules="required"
                    :name="`company-${i}`"
                    :label="i === 0 ? 'Empresa o startup' : ''"
                  />
                  <kalio-button
                    v-if="i > 0"
                    class="mb-px h-11 w-12 shrink-0 self-end bg-black text-blue-gray-50"
                    variant="custom"
                    left-icon="thrash.svg"
                    left-icon-classes="mr-0"
                    type="button"
                    @click=" formAnswers.experience.previousPositions.value.splice(i, 1)"
                  />
                  <div
                    v-else
                    class="w-12 shrink-0"
                  />
                </div>
              </template>
            </kalio-base-input>
            <kalio-button
              type="button"
              label="Agregar experiencia laboral"
              class="mt-4 self-start"
              @click="formAnswers.experience.previousPositions.value.push({ company: '', title: '' })"
            />
          </div>
        </div>
        <kalio-radio-inputs
          v-model="formAnswers.experience.otherStartup.value"
          name="other_startup"
          :rules="formAnswers.experience.otherStartup.rules"
          :label="formAnswers.experience.otherStartup.label"
          :values="['yes', 'no']"
          :radio-labels="['Sí', 'No']"
        />
        <kalio-long-text-input
          v-if="formAnswers.experience.otherStartup.value === 'yes'"
          v-model="formAnswers.experience.previousStartup.value"
          name="previous_startup"
          :rules="formAnswers.experience.previousStartup.rules"
          :label="formAnswers.experience.previousStartup.label"
        />
        <kalio-radio-inputs
          v-model="formAnswers.experience.technical.value"
          name="technical"
          :rules="formAnswers.experience.technical.rules"
          :label="formAnswers.experience.technical.label"
          :values="['yes', 'no']"
          :radio-labels="['Sí', 'No']"
        />
        <kalio-radio-inputs
          v-model="formAnswers.experience.knowsHowToCode.value"
          name="knows_how_to_code"
          :rules="formAnswers.experience.knowsHowToCode.rules"
          :label="formAnswers.experience.knowsHowToCode.label"
          :values="['yes', 'no']"
          :radio-labels="['Sí', 'No']"
        />
        <template v-if="formAnswers.experience.knowsHowToCode.value === 'yes'">
          <kalio-long-text-input
            v-model="formAnswers.experience.howLearnedToCode.value"
            name="how_learned_to_code"
            :rules="formAnswers.experience.howLearnedToCode.rules"
            :label="formAnswers.experience.howLearnedToCode.label"
          />
          <kalio-long-text-input
            v-model="formAnswers.experience.preferredLanguages.value"
            name="preferred_languages"
            :rules="formAnswers.experience.preferredLanguages.rules"
            :label="formAnswers.experience.preferredLanguages.label"
          />
        </template>
        <kalio-short-text-input
          v-model="formAnswers.experience.otherAccelerator.value"
          name="other_accelerator"
          :label="formAnswers.experience.otherAccelerator.label"
        />
      </kalio-form-section>

      <kalio-form-section title="Compromisos">
        <kalio-radio-inputs
          v-model="formAnswers.commitment.currentlyFullTime.value"
          name="currently_full_time"
          :rules="formAnswers.commitment.currentlyFullTime.rules"
          :label="formAnswers.commitment.currentlyFullTime.label"
          :values="['yes', 'no']"
          :radio-labels="['Sí', 'No']"
        />
        <kalio-short-text-input
          v-if="formAnswers.commitment.currentlyFullTime.value === 'yes'"
          v-model="formAnswers.commitment.fullTimeMonths.value"
          name="full_time_months"
          :rules="formAnswers.commitment.fullTimeMonths.rules"
          :label="formAnswers.commitment.fullTimeMonths.label"
        />
        <kalio-radio-inputs
          v-model="formAnswers.commitment.fullTime.value"
          name="full_time"
          :rules="formAnswers.commitment.fullTime.rules"
          :label="formAnswers.commitment.fullTime.label"
          :values="['yes', 'no']"
          :radio-labels="['Sí', 'No']"
        />
        <kalio-radio-inputs
          v-model="formAnswers.commitment.otherJobs.value"
          name="other_jobs"
          :rules="formAnswers.commitment.otherJobs.rules"
          :label="formAnswers.commitment.otherJobs.label"
          :values="['yes', 'no']"
          :radio-labels="['Sí', 'No']"
        />
      </kalio-form-section>

      <kalio-form-section title="Otros">
        <kalio-long-text-input
          v-model="formAnswers.other.hackerOrCool.value"
          name="hacker_or_cool"
          :rules="formAnswers.other.hackerOrCool.rules"
          :label="formAnswers.other.hackerOrCool.label"
          :description="formAnswers.other.hackerOrCool.description"
        />
      </kalio-form-section>

      <kalio-button
        label="Enviar información"
        class="w-full sm:w-auto sm:self-center sm:px-24"
        :loading="loading"
      />
    </vee-form>
  </div>
  <kalio-feedback-modal
    v-if="errorMessage"
    :title="errorMessage"
    status="error"
    accept="Entendido"
    @accept="errorMessage = null"
  />
</template>
