<script setup>
import { watch, onMounted, ref, computed, nextTick } from 'vue';
import hotkeys from 'hotkeys-js';
import { storeToRefs } from 'pinia';
import { useUrlSearchParams } from '@vueuse/core';
import { useStartupApplicationStore, useReviewFiltersStore } from '@/stores';
import { useResourcesTagsQuery } from '@/queries/tags.js';
import {
  useStartupApplicationQuery,
  useStartupApplicationsQuery,
} from '@/queries/internal/startup_applications.js';
import KalioSpinner from '@/components/shared/kalio-spinner.vue';
import BaseApplyReviewApplicationsFilters from '@/components/internal/apply/review/applications-filters.vue';
import BaseApplyReviewApplicationsList from '@/components/internal/apply/review/base-applications-list.vue';
import ApplyReviewStartupApplicationPanel from '@/components/internal/apply/review/startup-application-panel.vue';
import ApplyReviewFrankWriter from '@/components/internal/apply/review/frank-writer.vue';

const props = defineProps({
  applicationProcesses: { type: Array, required: true },
  aasmStates: { type: Array, required: true },
  countries: { type: Array, required: true },
  qualifications: { type: Array, required: true },
  reviewers: { type: Array, required: true },
});

const startupApplicationStore = useStartupApplicationStore();
const {
  currentStartupApplication,
  startupApplications,
  currentStartupApplicationIndex,
  currentStartupApplicationId,
} = storeToRefs(startupApplicationStore);
const {
  previousStartupApplication,
  nextStartupApplication,
  setFullStartupApplication,
  goToStartupApplication,
  setStartupApplications,
} = startupApplicationStore;

const reviewFiltersStore = useReviewFiltersStore();
const { appliedFilters } = storeToRefs(reviewFiltersStore);

const params = useUrlSearchParams();
const selectedSort = ref(params.sort || 'score');
function handleChangeSort() {
  if (selectedSort.value === 'score') {
    selectedSort.value = 'submitted_at';
  } else if (selectedSort.value === 'updated_at') {
    selectedSort.value = 'score';
  } else {
    selectedSort.value = 'updated_at';
  }
}
watch(selectedSort, () => {
  params.sort = selectedSort.value;
});

onMounted(() => {
  hotkeys('shift+left,shift+right', (event, handler) => {
    if (handler.key === 'shift+left') previousStartupApplication();
    if (handler.key === 'shift+right') nextStartupApplication();
  });
});

const { data: startupApplicationsTags } = useResourcesTagsQuery({
  resourceType: 'StartupApplication',
});

const { data: industries } = useResourcesTagsQuery({
  resourceType: 'StartupApplication',
  tagsOn: 'industries',
});

const firstPageToFetch = new URLSearchParams(window.location.search).get('page') || 1;
const {
  data: applicationsResponse,
  fetchNextPage,
  isFetchingNextPage,
  isLoading: isStartupApplicationsLoading,
} = useStartupApplicationsQuery({ filters: appliedFilters, sort: selectedSort, firstPageToFetch });
const isPagesEmpty = computed(
  () => (applicationsResponse.value?.pages || [])
    .map((page) => page.startupApplications.length)
    .flat().length === 0,
);

watch(applicationsResponse, (newData) => nextTick(
  () => setStartupApplications(
    (newData?.pages || []).map(page => page.startupApplications).flat(),
  ),
));

const totalCount = computed(() => applicationsResponse.value?.pages[0]?.totalCount || 0);
const currentPageItem = computed(() => {
  const currentPage = applicationsResponse.value?.pages?.find(page => page.startupApplications.some(
    application => application.id === currentStartupApplicationId.value,
  ))?.currentCursor || 1;
  const perPage = applicationsResponse.value?.pages[0]?.perPage || 0;

  return (Number(currentPage) - 1) * Number(perPage) +
    currentStartupApplicationIndex.value + 1;
});

const PAGE_FETCH_DISTANCE = 5;
watch(currentStartupApplicationId, () => {
  if (
    currentStartupApplicationIndex.value >= startupApplications.value.length -
      PAGE_FETCH_DISTANCE &&
    !isFetchingNextPage.value
  ) {
    fetchNextPage();
  }
});

const enabled = computed(() => !!currentStartupApplicationId.value);
const {
  isSuccess: isCurrentApplicationFetchSuccess,
  isFetching: isCurrentApplicationFetching,
} = useStartupApplicationQuery(
  currentStartupApplicationId,
  {
    onSuccess: (startupApplication) => setFullStartupApplication(startupApplication),
    enabled,
  },
);
</script>

<template>
  <div class="w-full max-w-screen-2xl">
    <div class="relative flex size-full flex-col gap-4 lg:flex-row">
      <div class="flex w-full shrink-0 flex-col space-y-4 sm:w-72 2xl:w-96">
        <base-apply-review-applications-filters
          :application-processes="props.applicationProcesses"
          :aasm-states="props.aasmStates"
          :countries="props.countries"
          :qualifications="props.qualifications"
          :reviewers="props.reviewers"
          :startup-application-tags="startupApplicationsTags"
          :industries="industries"
          :loading="isFetchingNextPage"
        />
        <base-apply-review-applications-list
          class="hidden lg:block"
          :selected-sort="selectedSort"
          :loading="isStartupApplicationsLoading"
          :loading-bottom="isFetchingNextPage"
          @change-sort="handleChangeSort"
          @set-application="goToStartupApplication"
          @scrolled-to-last="fetchNextPage"
        />
      </div>

      <div class="flex grow flex-col rounded-md border border-blue-gray-700 bg-pv-blue-900 p-4">
        <div class="flex flex-row items-center space-x-8 self-center">
          <button
            class="rounded-full bg-blue-gray-600 p-1.5 focus:ring-1 focus:ring-blue-gray-100"
            @click="previousStartupApplication"
          >
            <inline-svg
              :src="require('assets/images/icons/arrow.svg')"
              class="size-3 fill-current text-blue-gray-50"
            />
          </button>
          <span
            v-if="startupApplications.length"
            class="text-pv-gray"
          >
            {{ currentPageItem }}/{{ totalCount }}
          </span>
          <span v-else>
            (0/0)
          </span>
          <button
            class="rounded-full bg-blue-gray-600 p-1.5 focus:ring-1 focus:ring-blue-gray-100"
            @click="nextStartupApplication"
          >
            <inline-svg
              :src="require('assets/images/icons/arrow.svg')"
              class="size-3 rotate-180 fill-current text-blue-gray-50"
            />
          </button>
        </div>

        <kalio-spinner
          v-if="isCurrentApplicationFetching"
          class="mt-20 size-8 self-center"
        />
        <template v-else>
          <apply-review-startup-application-panel
            v-if="!isPagesEmpty &&
              currentStartupApplication &&
              currentStartupApplication.full"
            :qualifications="qualifications"
          />
          <span
            v-else
            class="mt-12 self-center text-blue-gray-300"
          >
            No hay resultados
          </span>
        </template>
      </div>
    </div>
    <apply-review-frank-writer />
  </div>
</template>
