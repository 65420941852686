<script setup>
import { computed } from 'vue';

const props = defineProps({
  startupApplication: { type: Object, default: null },
  applicationFounder: { type: Object, default: null },
  rounded: { type: Boolean, default: false },
});

const founderProfileTaskCompleted = computed(() => props.applicationFounder?.founderProfileSubmitted);

const allFounderProfilesSubmitted = computed(() => {
  if (!props.applicationFounder?.founderProfileSubmitted) return false;

  return props.startupApplication?.applicationFounders.every((founder) => founder.founderProfileSubmitted);
});

const interviewEnabled = computed(() => {
  if (props.startupApplication?.recordedInterviewCompleted) return false;

  return allFounderProfilesSubmitted.value;
});

function urlWithParams(url) {
  const passingParams = new URLSearchParams(window.location.search).toString();
  if (passingParams === '') return url;

  return `${url}?${passingParams}`;
}

</script>

<template>
  <div class="flex flex-col">
    <a :href="urlWithParams('/apply/profile/edit')">
      <base-task
        description="Completa tu perfil de fundador"
        :completed="founderProfileTaskCompleted"
        :rounded="rounded"
      />
    </a>
    <template v-if="applicationFounder?.secondaryApplicant">
      <base-task
        v-if="startupApplication?.submitted"
        description="Información de la startup"
        disabled
        completed
        :rounded="rounded"
      />
      <base-task
        v-else
        description="Información de la startup"
        disabled
        :rounded="rounded"
      >
        <kalio-alert
          status="notice"
          :title="startupApplication?.submitAlertTitle"
          :description="startupApplication?.submitAlertDescription"
          :deletable="false"
        />
      </base-task>
    </template>
    <template v-else>
      <a :href="urlWithParams('/apply/invite_cofounders')">
        <base-task
          description="Invita a tus cofundadores"
          :completed="startupApplication?.cofoundersInvited && allFounderProfilesSubmitted"
          :in-progress="startupApplication?.cofoundersInvited"
          :rounded="rounded"
        />
      </a>
      <a :href="urlWithParams('/apply/edit')">
        <base-task
          description="Información de la startup"
          :completed="startupApplication?.hasAllRequiredFields"
          :rounded="rounded"
        />
      </a>
    </template>
    <component
      :is="interviewEnabled ? 'a' : 'div'"
      v-if="!props.applicationFounder?.secondaryApplicant"
      :href="urlWithParams('/apply/recorded_interview')"
    >
      <base-task
        description="Entrevista automatizada"
        :completed="startupApplication?.recordedInterviewCompleted"
        :disabled="!interviewEnabled"
        :rounded="rounded"
      >
        <kalio-alert
          v-if="startupApplication?.cofoundersInvited && !allFounderProfilesSubmitted"
          status="notice"
          title="Todos los fundadores deben completar su perfil"
          description="Asegúrate de que todos los fundadores completen su perfil para realizar la entrevista."
          :deletable="false"
        />
      </base-task>
    </component>
  </div>
</template>
