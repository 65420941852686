<script setup>
import { format } from 'date-fns';
import { computed, ref } from 'vue';
import { storeToRefs } from 'pinia';
import { useEventBus } from '@vueuse/core';
import { openFrankWriterEventBusKey } from '@/utils/keys';
import { frankWriterPresets } from '@/utils/frank_writer_presets.js';
import { useStartupApplicationStore, useStartupApplicationDiscardStore, useRolesStore } from '@/stores';
import { useResourceTagsQuery, useDeleteResourceTagMutation } from '@/queries/tags.js';
import KalioCopyTextButton from '@/components/shared/kalio-copy-text-button.vue';
import ApplicationPeriodBadge from '@/components/startup-applications/application-period-badge.vue';
import ApplicationAasmStateBadge from '@/components/startup-applications/application-aasm-state-badge.vue';
import ApplicationQualificationBadge from '@/components/startup-applications/application-qualification-badge.vue';
import ApplyReviewStartupApplicationQuestionBar from
  '@/components/internal/apply/review/startup-application-question-bar.vue';
import ReviewApplicationTagsModal from './tags-modal.vue';
import ReviewApplicationDiscardEmailModal from './application-discard-email-modal.vue';
import ReviewApplicationMoreActionsMenu from './more-actions-menu.vue';
import ReviewApplicationUpdateStateModal from './application-update-state-modal.vue';
import ReviewApplicationIndustriesModal from './industries-modal.vue';
import ReviewApplicationUpdateQualificationModal from './application-update-qualification-modal.vue';

const rolesStore = useRolesStore();
const isApplicationReviewer = computed(() => rolesStore.isApplicationReviewer);

const DISCARD_REASONS = [
  { template: 'no_cto', reason: 'Por no tener CTO' },
  { template: 'no_tech', reason: 'Por no ser tecnológico' },
  { template: 'no_full_time', reason: 'Por no estar fulltime' },
  { template: 'without_feedback', reason: 'Porque sí' },
  { template: 'with_feedback', reason: 'Con feedback' },
  { template: 'custom', reason: 'Personalizado' },
];

const CUSTOM_DISCARD_FEEDBACK_REASONS = ['custom', 'with_feedback'];

defineEmits('update-qualification');
defineProps({
  qualifications: { type: Array, default: () => [] },
});

const selectedQualification = ref(null);
const qualificationModalOpen = ref(false);
const showQuestionBar = ref(false);
function openQualificationModal(qualification) {
  selectedQualification.value = qualification;
  qualificationModalOpen.value = true;
}

const selectedEvent = ref(null);
const eventModalOpen = ref(false);
function openEventModal(event) {
  selectedEvent.value = event;
  eventModalOpen.value = true;
}

const { discardReason } = storeToRefs(useStartupApplicationDiscardStore());
const openFrankWriterEventBus = useEventBus(openFrankWriterEventBusKey);
const discardEmailModalOpen = ref(false);
function setDiscardEmailReason(template) {
  if (CUSTOM_DISCARD_FEEDBACK_REASONS.includes(template)) {
    openFrankWriterEventBus.emit(frankWriterPresets.discardEmailPreset);
  }

  discardEmailModalOpen.value = true;
  discardReason.value = template;
}

const startupApplicationStore = useStartupApplicationStore();
const {
  currentStartupApplication: startupApplication,
  currentStartupApplicationId: startupApplicationId,
} = storeToRefs(startupApplicationStore);

const interviewsAreEvaluated = computed(() => {
  const first = startupApplication.value.firstInterview;
  const second = startupApplication.value.secondInterview;
  if (first && !first.decision) return false;
  if (second && !second.decision) return false;

  return true;
});

const canChangeState = computed(() => startupApplication.value.qualification && interviewsAreEvaluated.value);

const stateTooltipText = computed(() => {
  if (canChangeState.value) {
    return '';
  } else if (interviewsAreEvaluated.value) {
    return '⚠️ Debes calificar la aplicación antes de cambiar su estado';
  }

  return '❗ Debes evaluar las entrevistas antes de cambiar su estado';
});

const { data: tags } = useResourceTagsQuery({
  resourceType: 'StartupApplication',
  resourceIdRef: startupApplicationId,
});

const tagToDelete = ref(null);
const tagsModalOpen = ref(false);
const {
  isLoading: isDeleteTagLoading,
  mutate: deleteTag,
} = useDeleteResourceTagMutation({
  resourceType: 'StartupApplication',
  resourceIdRef: startupApplicationId,
}, { onMutate: (tag) => (tagToDelete.value = tag) });

const { data: industries } = useResourceTagsQuery({
  resourceType: 'StartupApplication',
  resourceIdRef: startupApplicationId,
  tagsOn: 'industries',
});

const industryModalOpen = ref(false);
const industryToDelete = ref(null);
const {
  isLoading: isDeleteIndustryLoading,
  mutate: deleteIndustry,
} = useDeleteResourceTagMutation({
  resourceType: 'StartupApplication',
  resourceIdRef: startupApplicationId,
  tagsOn: 'industries',
}, { onMutate: (industry) => (industryToDelete.value = industry) });

function sendEmailToFounders() {
  const foundersEmails = startupApplication.value.applicationFounders.map(
    (founder) => founder.user.email,
  ).join(',');

  window.open(
    `mailto:${foundersEmails}?subject=Hola%20fundadores%20de%20${startupApplication.value.startupName}`,
  );
}

const formattedSubmittedAt = computed(
  () => format(new Date(startupApplication.value.submittedAt), 'dd/MM/yyyy'),
);
</script>

<template>
  <div class="flex w-full flex-col">
    <div class="flex flex-col gap-4 md:flex-row md:items-center md:justify-between">
      <div class="flex flex-row items-center space-x-4">
        <a
          class="text-3xl font-medium transition hover:text-pv-yellow"
          :href="`/internal/apply/review/startup_applications/${startupApplicationId}`"
        >
          {{ startupApplication.startupName }}
        </a>
        <div class="flex flex-row items-center space-x-2">
          <a
            v-if="startupApplication.startupUrl"
            target="_blank"
            :href="startupApplication.startupUrl"
          >
            <inline-svg
              :src="require('assets/images/icons/chain.svg')"
              class="size-5 cursor-pointer stroke-current text-pv-light-blue hover:opacity-75"
            />
          </a>
          <kalio-copy-text-button
            :text="startupApplication.applicationUrl"
            title="Copiar URL de revisión"
            class="size-4 shrink-0 text-pv-light-blue"
          />
        </div>
      </div>
      <div class="flex flex-col items-end">
        <div
          v-if="startupApplication.reviewer"
          class="mb-2 mr-1 flex flex-row items-center space-x-1 text-pv-gray"
        >
          <inline-svg
            :src="require('assets/images/icons/outline/lock.svg')"
            class="size-4 stroke-current"
          />
          <span class="text-sm font-medium">
            {{ startupApplication.reviewer.firstName }}
          </span>
        </div>
        <div class="flex flex-row items-center space-x-2">
          <application-period-badge :application-period="startupApplication.applicationPeriod" />
          <application-qualification-badge
            v-if="startupApplication.qualification"
            :qualification="startupApplication.qualification"
          />
          <application-aasm-state-badge :aasm-state="startupApplication.aasmState" />
          <kalio-badge
            v-if="['scheduled', 'sent'].includes(startupApplication.discardEmailState)"
            :text="startupApplication.discardEmailState === 'sent' ? 'Correo enviado' : 'Correo programado'"
            color="pv-yellow"
            with-border
          />
        </div>
      </div>
    </div>
    <p class="mt-4 text-sm">
      {{ startupApplication.shortDescription }}
    </p>
    <div class="mt-4 flex flex-wrap gap-x-4 gap-y-2 text-xs text-blue-gray-300">
      <div
        v-if="startupApplication.country"
        class="shrink-0"
      >
        <div
          v-if="startupApplication.country.code"
          class="flex flex-col items-center"
        >
          <country-flag :country="startupApplication.country.code" />
          <span class="-mt-1">
            {{ startupApplication.country.name }}
          </span>
        </div>
        <span v-else>
          {{ startupApplication.country }}
        </span>
      </div>
      <div class="flex shrink-0 flex-row items-center space-x-1">
        <span>
          {{ startupApplication.startupApplicationInsight.score }}
        </span>
      </div>
      <div class="flex shrink-0 flex-row items-center space-x-1">
        <inline-svg
          :src="require('assets/images/icons/solid/user.svg')"
          class="size-3 fill-current"
        />
        <span>
          {{ startupApplication.applicationFounders.length }}
          {{ $tc('startupApplication.review.founders', startupApplication.applicationFounders.length) }}
        </span>
      </div>
      <div class="flex shrink-0 flex-row items-center space-x-1">
        <inline-svg
          :src="require('assets/images/icons/calendar.svg')"
          class="size-3 fill-current"
        />
        <span>
          {{ formattedSubmittedAt }}
        </span>
      </div>
      <div
        v-if="tags && tags.length > 0"
        class="flex flex-row items-center space-x-2"
      >
        <span class="flex flex-row items-center space-x-1">
          <inline-svg
            :src="require('assets/images/icons/solid/tag.svg')"
            class="w-4 fill-current"
          />
          <span>
            :
          </span>
        </span>
        <div class="flex flex-1 flex-wrap gap-2">
          <kalio-badge
            v-for="(tag, index) in tags"
            :key="`${tag}-${index}`"
            :text="$filters.humanize(tag)"
            color="gray"
            with-border
            :deletable="isApplicationReviewer"
            :delete-loading="isDeleteTagLoading && tagToDelete === tag"
            @delete="deleteTag(tag)"
          />
        </div>
      </div>
      <div
        v-if="industries && industries.length > 0"
        class="flex flex-row items-center space-x-2"
      >
        <span class="flex flex-row items-center space-x-1">
          <inline-svg
            :src="require('assets/images/icons/solid/building-office.svg')"
            class="w-4 fill-current"
          />
          <span>
            :
          </span>
        </span>
        <div class="flex flex-1 flex-wrap gap-2">
          <kalio-badge
            v-for="(industry, index) in industries"
            :key="`${industry}-${index}`"
            :text="industry"
            color="indigo"
            with-border
            :deletable="isApplicationReviewer"
            :delete-loading="isDeleteIndustryLoading && industryToDelete === industry"
            @delete="deleteIndustry(industry)"
          />
        </div>
      </div>
    </div>
    <div class="mt-4 flex w-full flex-wrap gap-x-4 gap-y-2">
      <template v-if="isApplicationReviewer">
        <div
          v-if="!startupApplication.discarded"
          class="flex flex-col gap-4 md:flex-row"
        >
          <VDropdown v-if="!startupApplication.qualification">
            <kalio-button label="Calificar" />
            <template #popper="{ hide }">
              <div class="flex flex-col space-y-2 p-2">
                <button
                  v-for="(qualification, index) in qualifications"
                  :key="`qualification-${index}`"
                  class="rounded-md px-4 py-2 first-letter:capitalize hover:bg-gray-600"
                  @click="hide(); openQualificationModal(qualification)"
                >
                  {{ $filters.humanize(qualification) }}
                </button>
              </div>
            </template>
          </VDropdown>
          <VDropdown
            v-if="startupApplication.possibleEvents.length > 0"
            :disabled="!startupApplication.qualification"
          >
            <div v-tooltip="stateTooltipText">
              <kalio-button
                label="Cambiar estado"
                variant="blue"
                :disabled="!canChangeState"
              />
            </div>
            <template #popper="{ hide }">
              <div class="flex flex-col space-y-2 p-2">
                <button
                  v-for="(event, index) in startupApplication.possibleEvents"
                  :key="`event-${index}`"
                  class="rounded-md px-4 py-2 first-letter:capitalize hover:bg-gray-600"
                  @click="hide(); openEventModal(event)"
                >
                  {{ $filters.humanize(event) }}
                </button>
              </div>
            </template>
          </VDropdown>
        </div>
        <div v-else-if="startupApplication.discardEmailState === 'unprocessed'">
          <VDropdown>
            <kalio-button label="Programar correo de descarte" />
            <template #popper="{ hide }">
              <div class="flex flex-col space-y-2 p-2">
                <button
                  v-for="(item, index) in DISCARD_REASONS"
                  :key="`discard-reason-${index}`"
                  class="rounded-md px-4 py-2 first-letter:capitalize hover:bg-gray-600"
                  @click="hide(); setDiscardEmailReason(item.template)"
                >
                  {{ item.reason }}
                </button>
              </div>
            </template>
          </VDropdown>
        </div>
      </template>
      <button
        class="group flex items-center justify-center gap-1 rounded-md border border-transparent p-1.5 text-white transition-all duration-200 hover:border-pv-yellow"
        @click="showQuestionBar = true"
      >
        <inline-svg
          :src="require('assets/images/icons/sparkles.svg')"
          class="size-5 transition-colors duration-200 group-hover:text-pv-yellow"
        />
        <span class="whitespace-nowrap text-xs text-pv-yellow opacity-0 transition-opacity duration-200 group-hover:opacity-100">
          shift + k
        </span>
      </button>
      <slot name="buttons" />
      <review-application-more-actions-menu
        v-if="isApplicationReviewer"
        class="self-end sm:ml-auto"
        :with-edit-qualification-button="!!startupApplication.qualification"
        :qualification-options="qualifications"
        @edit-tags="tagsModalOpen = true"
        @send-email="sendEmailToFounders"
        @edit-industry="industryModalOpen = true"
        @edit-qualification="openQualificationModal"
      />
    </div>
    <review-application-update-qualification-modal
      v-if="qualificationModalOpen"
      :qualification="selectedQualification"
      @close="qualificationModalOpen = false"
    />
    <review-application-update-state-modal
      :show="eventModalOpen"
      :selected-event="selectedEvent"
      @close="eventModalOpen = false"
    />
    <review-application-discard-email-modal
      v-if="discardEmailModalOpen"
      @close="discardEmailModalOpen = false"
    />
    <review-application-tags-modal
      :show="tagsModalOpen"
      @close="tagsModalOpen = false"
    />
    <review-application-industries-modal
      :show="industryModalOpen"
      @close="industryModalOpen = false"
    />
  </div>
  <apply-review-startup-application-question-bar
    :is-open="showQuestionBar"
    @close="showQuestionBar = false"
    @open="showQuestionBar = true"
  />
</template>
