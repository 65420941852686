<script>
import { inject } from 'vue';
import { format, subMonths, parseISO } from 'date-fns';
import es from 'date-fns/locale/es';
import KalioFormWrapper from '@/components/shared/kalio-form-wrapper.vue';
import KalioFormSection from '@/components/form/kalio-form-section';
import KalioFeedbackModal from '@/components/shared/kalio-feedback-modal.vue';
import { formatCountriesApiResponse } from '@/utils/countries.js';
import startupApplicationApi from '@/api/startup_application';
import countriesApi from '@/api/countries';
import emitter from '@/utils/emitter.js';
import prefillFormData from '@/utils/prefill_form.js';
import { MONTHS, getLastMonths } from '@/utils/dates.js';
import { currentUserKey } from '@/utils/keys.js';

export default {
  name: 'startup-application-form',
  components: {
    KalioFormSection,
    KalioFeedbackModal,
    KalioFormWrapper,
  },
  props: {
    applicationProcess: { type: Object, required: true },
    startupApplication: { type: Object, default: () => ({}) },
    industries: { type: Array, required: true },
  },
  setup() {
    return {
      currentUser: inject(currentUserKey),
      es,
    };
  },
  data() {
    return {
      MONTHS,
      loading: false,
      errorMessage: false,
      countries: [],
      revenuesLength: 3,
      dynamicStartupApplication: {
        startupName: null,
        startupUrl: null,
        shortDescription: null,
        longDescription: null,
        country: null,
        ...this.startupApplication,
      },
      formAnswers: {
        contact: {
          founderEmail: {
            label: 'Email',
            value: this.currentUser.email,
            dataPath: 'formAnswers.contact.founderEmail',
            component: 'kalio-short-text-input',
          },
          founderName: {
            label: 'Nombre completo',
            value: this.currentUser.fullName,
            dataPath: 'formAnswers.contact.founderName',
            component: 'kalio-short-text-input',
          },
        },
        basicInfo: {
          industries: {
            label: 'Industria(s) de tu startup',
            value: null,
            dataPath: 'formAnswers.basicInfo.industries',
            component: 'kalio-select-input',
          },
        },
        founders: {
          history: {
            label: '¿Cuáles son sus roles y cuál es la historia de cada uno?',
            description: 'También cuéntanos cómo se conocieron, y qué han hecho en el pasado.',
            value: null,
            dataPath: 'formAnswers.founders.history',
            component: 'kalio-long-text-input',
          },
          hackerExists: {
            label: '¿Alguno de los fundadores sabe programar?',
            value: null,
            dataPath: 'formAnswers.founders.hackerExists',
            component: 'kalio-radio-inputs',
            radioLabels: ['Sí', 'No'],
          },
          engineer: {
            label: '¿Quién escribe el código o hace el trabajo técnico en el producto?' +
                    ' ¿Es fundador?',
            value: null,
            dataPath: 'formAnswers.founders.engineer',
            component: 'kalio-long-text-input',
          },
        },
        progress: {
          general: {
            label: 'Cuéntanos el estado actual de tu startup',
            value: null,
            dataPath: 'formAnswers.progress.general',
            component: 'kalio-long-text-input',
          },
          teamSize: {
            label: '¿Cuál es el tamaño actual del equipo?',
            description: 'El total considerando fundadores y empleados.',
            value: null,
            dataPath: 'formAnswers.founders.teamSize',
            component: 'kalio-short-text-input',
          },
          productDemoVideo: {
            label: 'Demo del producto (si es que existe)',
            description: 'El video debe estar en Youtube como "oculto" (o "unlisted" en inglés)',
            value: null,
            dataPath: 'formAnswers.progress.productDemoVideo',
            component: 'kalio-short-text-input',
          },
          appUrl: {
            label: 'URL de tu aplicación (si es que existe)',
            description: 'Link para tu aplicación deployeada. Si es una app móvil, ' +
              'link de descarga App Store / Google Play / Otro.',
            value: null,
            dataPath: 'formAnswers.progress.appUrl',
            component: 'kalio-short-text-input',
          },
          appUrlAccessDetails: {
            label: 'Credenciales o instrucciones para acceder a tu aplicación',
            description: 'Puedes crear una cuenta de prueba para que podamos acceder a tu aplicación.',
            value: null,
            dataPath: 'formAnswers.progress.appUrlAccessDetails',
            component: 'kalio-short-text-input',
          },
          monthsWorkingInIdea: {
            label: 'Número de meses desde que empezaron a trabajar en esta idea',
            description: 'Queremos saber hace cuánto empezaron a trabajar en esto, al menos algunas horas a la semana.',
            value: null,
            dataPath: 'formAnswers.progress.monthsWorkingInIdea',
            component: 'kalio-short-text-input',
          },
          users: {
            label: '¿Hay personas usando su producto?',
            value: null,
            dataPath: 'formAnswers.progress.users',
            component: 'kalio-radio-inputs',
            radioLabels: ['Sí', 'No'],
          },
          usersAmount: {
            label: '¿Cuántas personas?',
            value: null,
            dataPath: 'formAnswers.progress.usersAmount',
            component: 'kalio-short-text-input',
          },
          monthsWithUsers: {
            label: '¿Hace cuántos meses tienen usuarios?',
            value: null,
            dataPath: 'formAnswers.progress.monthsWithUsers',
            component: 'kalio-short-text-input',
          },
          hasRevenue: {
            label: '¿Tu startup tiene ingresos actualmente?',
            value: null,
            dataPath: 'formAnswers.progress.hasRevenue',
            component: 'kalio-radio-inputs',
            radioLabels: ['Sí', 'No'],
          },
          monthsWithRevenue: {
            label: '¿Hace cuántos meses empezaron a tener ingresos?',
            value: null,
            dataPath: 'formAnswers.progress.monthsWithRevenue',
            component: 'kalio-short-text-input',
          },
          revenues: {
            label: 'Cuéntanos cuáles han sido los ingresos de tu startup los últimos meses. ' +
                    'Puedes ingresar la cantidad de meses que quieras.',
            description: 'Debes ingresar la cantidad (en dólares) comenzando por el último mes.',
            value: [],
            dataPath: 'formAnswers.progress.revenues',
            component: 'kalio-short-text-input',
          },
          revenueOther: {
            label: '¿Algo más que quieras contarnos sobre tus ingresos?',
            value: null,
            dataPath: 'formAnswers.progress.revenueOther',
            component: 'kalio-short-text-input',
          },
          otherProgram: {
            label: '¿Han participado y sido seleccionados en otro programa de startups, con esta startup? ¿En cuál?',
            value: null,
            dataPath: 'formAnswers.progress.otherProgram',
            component: 'kalio-short-text-input',
          },
        },
        idea: {
          why: {
            label: '¿Por qué eligieron esta idea? ¿Son expertos en el tema?' +
                     ' ¿Cómo saben que hay gente que quiere lo que están haciendo?',
            value: null,
            dataPath: 'formAnswers.idea.why',
            component: 'kalio-long-text-input',
          },
          competition: {
            label: '¿Quiénes son sus competidores y quiénes podrían convertirse en competidores?' +
                     ' ¿A quién le tienen más miedo?',
            value: null,
            dataPath: 'formAnswers.idea.competition',
            component: 'kalio-long-text-input',
          },
          insight: {
            label: '¿Qué saben o tienen ustedes que hará su producto exitoso y único frente a la competencia?',
            value: null,
            dataPath: 'formAnswers.idea.insight',
            component: 'kalio-long-text-input',
          },
          whyFirst: {
            label: '¿Por qué nadie lo ha hecho hasta ahora?',
            value: null,
            dataPath: 'formAnswers.idea.whyFirst',
            component: 'kalio-long-text-input',
          },
          money: {
            label: '¿Cómo generan o generarán ingresos?',
            value: null,
            dataPath: 'formAnswers.idea.money',
            component: 'kalio-long-text-input',
          },
          opportunitySize: {
            label: '¿A qué nivel podrían llegar esos ingresos?',
            description: 'Tomando en cuenta el futuro de la startup y el mercado.',
            value: null,
            dataPath: 'formAnswers.idea.opportunitySize',
            component: 'kalio-long-text-input',
          },
        },
        legal: {
          equityByFounder: {
            label: '¿Qué porcentaje de la empresa tiene o tendrá cada fundador?',
            description: 'En esta pregunta nos interesa saber solo los fundadores.' +
              ' En la pregunta que viene después puedes incluir tu composición societaria completa.' +
              ' Si tu co-founder no aparece, debes invitarlo primero.',
            value: this.startupApplication.applicationFounders.map((founder) => ({
              name: founder.user.email,
              equity: 0,
              id: founder.id,
            })),
            dataPath: 'formAnswers.legal.equityByFounder',
            component: 'kalio-long-text-input',
          },
          yieldEquity: {
            label: '¿Cuánto porcentaje de la empresa han cedido en total?',
            description: 'Si no tienes el número exacto, ingresa un estimado.',
            value: null,
            dataPath: 'formAnswers.legal.equity',
            component: 'kalio-long-text-input',
          },
          equity: {
            label: 'Ahora cuéntanos cómo se divide o dividirá tu empresa con todos los detalles y entidades.',
            description: 'Incluye cuánto tiene cada fundador, empleado, inversionista,' +
              ' o cualquier otra persona o entidad.' +
              ' Menciona si es que hay vestings, pools de acciones, acciones en tesorería, etc.' +
              ' Si aún no tienen una empresa constituida, cuéntanos cuáles son los planes.' +
              ' Pon cada entidad en lineas separadas.',
            value: null,
            dataPath: 'formAnswers.legal.equity',
            component: 'kalio-long-text-input',
          },
          entity: {
            label: '¿Tienen una empresa o entidad legal formada?',
            value: null,
            dataPath: 'formAnswers.legal.entity',
            component: 'kalio-radio-inputs',
            radioLabels: ['Sí', 'No'],
          },
          structure: {
            label: '¿Nos podrías dar más detalles sobre su estructura legal?',
            value: null,
            dataPath: 'formAnswers.legal.structure',
            component: 'kalio-long-text-input',
          },
          otherInvestments: {
            label: '¿Han aceptado alguna inversión?',
            value: null,
            dataPath: 'formAnswers.legal.otherInvestments',
            component: 'kalio-radio-inputs',
            radioLabels: ['Sí', 'No'],
          },
          otherInvestmentsDetails: {
            label: 'Lista cualquier inversión que tu empresa haya recibido.',
            description: 'Incluye el nombre del inversionista, el monto, la valorización, y la fecha.' +
            ' Ingresa cada uno en una nueva línea.',
            value: null,
            dataPath: 'formAnswers.legal.otherInvestmentsDetails',
            component: 'kalio-long-text-input',
          },
          funding: {
            label: '¿Cuánto dinero han levantado en total? (en dólares)',
            value: null,
            dataPath: 'formAnswers.legal.funding',
            component: 'kalio-short-text-input',
          },
          spending: {
            label: '¿Cuánto dinero gasta su startup al mes? (en dólares)',
            value: null,
            dataPath: 'formAnswers.legal.spending',
            component: 'kalio-short-text-input',
          },
          savings: {
            label: '¿Cuánto dinero tiene la startup en su cuenta de banco? (en dólares)',
            value: null,
            dataPath: 'formAnswers.legal.savings',
            component: 'kalio-short-text-input',
          },
          runway: {
            label: '¿Cuántos meses tienen de runway? Sólo consideren el dinero con el que cuentan actualmente.',
            dataPath: 'formAnswers.legal.runway',
            value: null,
            component: 'kalio-short-text-input',
          },
          currentInvestmentRound: {
            label: '¿Están actualmente levantando una ronda de inversión?',
            value: null,
            dataPath: 'formAnswers.legal.currentInvestmentRound',
            component: 'kalio-radio-inputs',
            radioLabels: ['Sí', 'No'],
          },
          currentInvestmentRoundDetails: {
            label: '¿Nos podrías dar más detalles de su ronda?',
            dataPath: 'formAnswers.legal.currentInvestmentRoundDetails',
            value: null,
          },
          other: {
            label: '¿Hay algo más que debamos saber sobre la empresa?',
            description: 'Otros temas legales, cofundadores que se hayan ido, etc',
            value: null,
            dataPath: 'formAnswers.legal.other',
            component: 'kalio-long-text-input',
          },
        },
        other: {
          alternativeIdea: {
            label: '¿Pensaron postular con otra idea? Explícala.',
            value: null,
            dataPath: 'formAnswers.other.alternativeIdea',
            component: 'kalio-long-text-input',
          },
          techStack: {
            label: '¿Qué stack tecnológico utilizas o planeas utilizar para desarrollar este producto?',
            value: null,
            dataPath: 'formAnswers.other.techStack',
            component: 'kalio-long-text-input',
          },
          previousApplication: {
            label: 'Si es que estás postulando con la misma idea de la vez pasada,' +
              ' ¿qué ha cambiado? Si es que pivotearon, ¿por qué pivotearon y qué aprendieron?',
            value: null,
            dataPath: 'formAnswers.other.previousApplication',
            component: 'kalio-long-text-input',
          },
        },
        curious: {
          whyPV: {
            label: '¿Por qué decidiste postular a Platanus?',
            value: null,
            dataPath: 'formAnswers.curious.whyPV',
            component: 'kalio-long-text-input',
          },
          acquisitionChannel: {
            label: '¿Cómo supiste de Platanus?',
            value: null,
            dataPath: 'formAnswers.curious.acquisitionChannel',
            component: 'kalio-long-text-input',
          },
        },
      },
    };
  },
  watch: {
    'formAnswers.progress.hasRevenue.value': {
      handler() {
        if (
          this.formAnswers.progress.hasRevenue.value === 'yes' &&
          this.formAnswers.progress.revenues.value.length === 0
        ) {
          this.formAnswers.progress.revenues.value = getLastMonths()
            .map(date => ({ date: format(date, 'yyy-MM'), amount: 0 }));
        } else if (this.formAnswers.progress.hasRevenue.value === 'no') {
          this.formAnswers.progress.revenues.value = [];
        }
      },
      immediate: true,
    },
  },
  mounted() {
    prefillFormData(this.formAnswers, this.dynamicStartupApplication.formAnswers);

    this.updateEquityByFounder();
    this.formAnswers.progress.revenues.value?.reverse();

    countriesApi.getCountries().then(response => {
      this.countries = formatCountriesApiResponse(response);
    });

    emitter.on('submit', this.updateApplication);
  },
  methods: {
    parseISO,
    format,
    removeZombieAnswers() {
      const dependencyTree = {
        progress: {
          users: {
            yes: ['monthsWithUsers', 'usersAmount'],
          },
          hasRevenue: {
            yes: ['monthsWithRevenue', 'revenues', 'revenueOther'],
          },
        },
        legal: {
          entity: {
            yes: ['structure'],
          },
          otherInvestments: {
            yes: ['otherInvestmentsDetails', 'funding'],
          },
          currentInvestmentRound: {
            yes: ['currentInvestmentRoundDetails'],
          },
        },
      };
      Object.keys(dependencyTree).forEach((section) => {
        Object.keys(dependencyTree[section]).forEach((question) => {
          const answer = this.formAnswers[section][question].value;
          if (answer === 'no') {
            dependencyTree[section][question].yes?.forEach((dependentQuestion) => {
              this.formAnswers[section][dependentQuestion].value = null;
            });
          } else if (answer === 'yes') {
            dependencyTree[section][question].no?.forEach((dependentQuestion) => {
              this.formAnswers[section][dependentQuestion].value = null;
            });
          }
        });
      });
    },
    updateEquityByFounder() {
      const equityAnswers = this.formAnswers.legal.equityByFounder.value;
      const updatedAnswers = this.startupApplication.applicationFounders.map((founder) => {
        const currentAnswer = equityAnswers.find((ans) => ans.id === founder.id);
        if (currentAnswer) return currentAnswer;

        return {
          name: founder.user.email,
          equity: 0,
          id: founder.id,
        };
      });
      this.formAnswers.legal.equityByFounder.value = [...updatedAnswers];
    },
    updateApplication() {
      this.loading = true;

      this.formAnswers.progress.revenues.value.reverse();
      this.removeZombieAnswers();
      startupApplicationApi.update(this.dynamicStartupApplication.id, {
        applicationProcessId: this.applicationProcess.id,
        ...this.dynamicStartupApplication,
        formAnswers: this.formAnswers,
      })
        .then(() => {
          window.location.href = '/apply?application_saved=true';
        })
        .catch(error => {
          this.errorMessage = error.response.data.detail;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    removeRevenue(index) {
      this.formAnswers.progress.revenues.value.splice(index, 1);
    },
    addRevenue() {
      let date;
      if (this.formAnswers.progress.revenues.value.length === 0) {
        date = subMonths(new Date(), 1);
      } else {
        date = subMonths(parseISO(this.formAnswers.progress.revenues.value.slice(-1)[0].date), 1);
      }

      this.formAnswers.progress.revenues.value.push({
        date: format(date, 'yyyy-MM'),
        amount: 0,
      });
    },
  },
};
</script>

<template>
  <kalio-form-wrapper
    allow-invalid-submit
    @submit="updateApplication"
  >
    <div class="flex flex-col">
      <div class="flex flex-col space-y-9 sm:space-y-12">
        <kalio-form-section
          section-name="Contacto"
          title="Contacto"
        >
          <kalio-short-text-input
            v-model="formAnswers.contact.founderEmail.value"
            name="founder_email"
            rules="required"
            :label="formAnswers.contact.founderEmail.label"
          />
          <kalio-short-text-input
            v-model="formAnswers.contact.founderName.value"
            name="founder_name"
            rules="required"
            :label="formAnswers.contact.founderName.label"
          />
        </kalio-form-section>

        <kalio-form-section
          section-name="Inf. básica"
          title="Información básica"
        >
          <kalio-short-text-input
            v-model="dynamicStartupApplication.startupName"
            name="startup_name"
            rules="required"
            label="Nombre de tu startup"
          />
          <kalio-short-text-input
            v-model="dynamicStartupApplication.startupUrl"
            name="startup_url"
            rules="url"
            label="Página web (si es que existe)"
          />
          <kalio-select-input
            v-model="dynamicStartupApplication.country"
            name="country"
            rules="required"
            label="País de la startup"
            :options="countries"
            :get-option-label="(option) => option.name"
          />
          <kalio-select-input
            v-model="formAnswers.basicInfo.industries.value"
            multiple
            name="industries"
            rules="required"
            :label="formAnswers.basicInfo.industries.label"
            :options="industries"
            :get-option-label="(option) => option.name"
          />
          <kalio-short-text-input
            v-model="dynamicStartupApplication.shortDescription"
            name="short_description"
            rules="required"
            :label="$t('startupApplication.shortDescription')"
          />
          <kalio-long-text-input
            v-model="dynamicStartupApplication.longDescription"
            name="long_description"
            rules="required"
            :label="$t('startupApplication.longDescription')"
          />
        </kalio-form-section>

        <kalio-form-section
          section-name="Founders"
          title="Founders"
        >
          <kalio-long-text-input
            v-model="formAnswers.founders.history.value"
            name="history"
            rules="required"
            :label="formAnswers.founders.history.label"
            :description="formAnswers.founders.history.description"
          />
          <kalio-radio-inputs
            v-model="formAnswers.founders.hackerExists.value"
            name="hacker_exists"
            rules="required"
            :label="formAnswers.founders.hackerExists.label"
            :values="['yes', 'no']"
            :radio-labels="formAnswers.founders.hackerExists.radioLabels"
          />
          <kalio-long-text-input
            v-model="formAnswers.founders.engineer.value"
            name="engineer"
            rules="required"
            :label="formAnswers.founders.engineer.label"
          />
        </kalio-form-section>

        <kalio-form-section
          section-name="Progreso"
          title="Progreso"
        >
          <kalio-long-text-input
            v-model="formAnswers.progress.general.value"
            name="general"
            rules="required"
            :label="formAnswers.progress.general.label"
          />
          <kalio-short-text-input
            v-model="formAnswers.progress.teamSize.value"
            name="team_size"
            rules="required|numeric"
            :label="formAnswers.progress.teamSize.label"
          />
          <kalio-short-text-input
            v-model="formAnswers.progress.productDemoVideo.value"
            name="product_demo_video"
            rules="url"
            placeholder="https://youtube.com/.."
            :label="formAnswers.progress.productDemoVideo.label"
            :description="formAnswers.progress.productDemoVideo.description"
          />
          <kalio-short-text-input
            v-model="formAnswers.progress.appUrl.value"
            name="app_url"
            rules="url"
            placeholder="https://"
            :label="formAnswers.progress.appUrl.label"
            :description="formAnswers.progress.appUrl.description"
          />
          <kalio-long-text-input
            v-if="formAnswers.progress.appUrl.value"
            v-model="formAnswers.progress.appUrlAccessDetails.value"
            name="app_url_access_details"
            :label="formAnswers.progress.appUrlAccessDetails.label"
            :description="formAnswers.progress.appUrlAccessDetails.description"
          />
          <kalio-short-text-input
            v-model="formAnswers.progress.monthsWorkingInIdea.value"
            name="months_working_in_idea"
            rules="required|numeric"
            :label="formAnswers.progress.monthsWorkingInIdea.label"
            :description="formAnswers.progress.monthsWorkingInIdea.description"
          />
          <kalio-radio-inputs
            v-model="formAnswers.progress.users.value"
            name="users"
            rules="required"
            :label="formAnswers.progress.users.label"
            :values="['yes', 'no']"
            :radio-labels="['Sí', 'No']"
          />
          <template v-if="formAnswers.progress.users.value === 'yes'">
            <kalio-short-text-input
              v-model="formAnswers.progress.usersAmount.value"
              name="users_amount"
              rules="required|numeric"
              :label="formAnswers.progress.usersAmount.label"
            />
            <kalio-short-text-input
              v-model="formAnswers.progress.monthsWithUsers.value"
              name="months_with_users"
              rules="required|numeric"
              :label="formAnswers.progress.monthsWithUsers.label"
            />
          </template>
          <kalio-radio-inputs
            v-model="formAnswers.progress.hasRevenue.value"
            name="has_revenue"
            rules="required"
            :label="formAnswers.progress.hasRevenue.label"
            :values="['yes', 'no']"
            :radio-labels="formAnswers.progress.hasRevenue.radioLabels"
          />
          <template v-if="formAnswers.progress.hasRevenue.value === 'yes'">
            <kalio-short-text-input
              v-model="formAnswers.progress.monthsWithRevenue.value"
              name="months_with_revenue"
              rules="required|numeric"
              :label="formAnswers.progress.monthsWithRevenue.label"
            />
            <div class="pb-4">
              <kalio-short-text-input
                name="revenues_hidden"
                rules="required"
                :model-value="formAnswers.progress.revenues.value.length > 0 ? 'fill' : null"
                class="hidden"
              />

              <div class="flex flex-col">
                <span class="text-lg font-medium text-blue-gray-200">
                  {{ formAnswers.progress.revenues.label }}
                </span>
                <p class="mt-2 text-pv-gray">
                  {{ formAnswers.progress.revenues.description }}
                </p>

                <div
                  v-for="(revenue, index) in formAnswers.progress.revenues.value"
                  :key="`${revenue.date}`"
                  class="mt-2 flex flex-row space-x-2"
                >
                  <kalio-text-answer
                    :answer="format(parseISO(revenue.date), 'MMMM, yyyy', { locale: es })"
                    rules="required"
                    :name="`month-${index}`"
                    :label="index === 0 ? 'Mes' : ''"
                    :options="MONTHS"
                    :display-error="index === formAnswers.progress.revenues.value.length - 1"
                    absolute-error
                    :editable="false"
                  />
                  <kalio-short-text-input
                    v-model="formAnswers.progress.revenues.value[index].amount"
                    rules="required|integer|min_value:0"
                    :name="`amount-${revenue.date}`"
                    :label="index === 0 ? 'Ingresos' : ''"
                    absolute-error
                    :display-error="index === formAnswers.progress.revenues.value.length - 1"
                  />
                  <kalio-button
                    v-if="index === formAnswers.progress.revenues.value.length - 1"
                    class="mb-px h-11 w-12 shrink-0 self-end bg-black text-blue-gray-50"
                    variant="custom"
                    left-icon="thrash.svg"
                    left-icon-classes="mr-0"
                    type="button"
                    @click="removeRevenue(index)"
                  />
                  <div
                    v-else
                    class="w-12 shrink-0"
                  />
                </div>
                <kalio-button
                  type="button"
                  label="Agregar mes"
                  class="mt-8 self-start"
                  @click="addRevenue"
                />
              </div>
            </div>
            <kalio-short-text-input
              v-model="formAnswers.progress.revenueOther.value"
              name="revenue_other"
              :label="formAnswers.progress.revenueOther.label"
            />
          </template>
          <kalio-short-text-input
            v-model="formAnswers.progress.otherProgram.value"
            name="other_program"
            rules="required"
            :label="formAnswers.progress.otherProgram.label"
          />
        </kalio-form-section>

        <kalio-form-section
          section-name="Idea"
          title="Idea"
        >
          <kalio-long-text-input
            v-model="formAnswers.idea.why.value"
            name="why"
            rules="required"
            :label="formAnswers.idea.why.label"
          />
          <kalio-long-text-input
            v-model="formAnswers.idea.competition.value"
            name="competition"
            rules="required"
            :label="formAnswers.idea.competition.label"
          />
          <kalio-long-text-input
            v-model="formAnswers.idea.insight.value"
            name="insight"
            rules="required"
            :label="formAnswers.idea.insight.label"
          />
          <kalio-long-text-input
            v-model="formAnswers.idea.whyFirst.value"
            name="why_first"
            rules="required"
            :label="formAnswers.idea.whyFirst.label"
          />
          <kalio-long-text-input
            v-model="formAnswers.idea.money.value"
            name="money"
            rules="required"
            :label="formAnswers.idea.money.label"
          />
          <kalio-long-text-input
            v-model="formAnswers.idea.opportunitySize.value"
            name="opportunity_size"
            rules="required"
            :label="formAnswers.idea.opportunitySize.label"
            :description="formAnswers.idea.opportunitySize.description"
          />
        </kalio-form-section>

        <kalio-form-section
          section-name="Legal"
          title="Legal"
          :scroll-options="{ behavior: 'smooth', block: 'start' }"
        >
          <div class="flex flex-col">
            <span class="text-lg font-medium text-blue-gray-200">
              {{ formAnswers.legal.equityByFounder.label }}
            </span>
            <p class="mt-2 text-pv-gray">
              {{ formAnswers.legal.equityByFounder.description }}
            </p>

            <div
              v-for="(founderEquity, i) in formAnswers.legal.equityByFounder.value"
              :key="i"
              class="mt-2 flex flex-row space-x-2"
            >
              <kalio-text-answer
                :answer="founderEquity.name"
                :linkified="false"
              />
              <kalio-short-text-input
                v-model="formAnswers.legal.equityByFounder.value[i].equity"
                :name="`equity_by_founder-${i}`"
                rules="required|numeric"
              />
            </div>
          </div>
          <kalio-short-text-input
            v-model="formAnswers.legal.yieldEquity.value"
            name="yieldEquity"
            rules="required|numeric"
            :label="formAnswers.legal.yieldEquity.label"
            :description="formAnswers.legal.yieldEquity.description"
          />
          <kalio-long-text-input
            v-model="formAnswers.legal.equity.value"
            name="equity"
            rules="required"
            :label="formAnswers.legal.equity.label"
            :description="formAnswers.legal.equity.description"
          />
          <kalio-radio-inputs
            v-model="formAnswers.legal.entity.value"
            name="entity"
            rules="required"
            :label="formAnswers.legal.entity.label"
            :values="['yes', 'no']"
            :radio-labels="['Sí', 'No']"
          />
          <kalio-long-text-input
            v-if="formAnswers.legal.entity.value === 'yes'"
            v-model="formAnswers.legal.structure.value"
            name="structure"
            rules="required"
            :label="formAnswers.legal.structure.label"
          />
          <kalio-radio-inputs
            v-model="formAnswers.legal.otherInvestments.value"
            name="other_investments"
            rules="required"
            :label="formAnswers.legal.otherInvestments.label"
            :values="['yes', 'no']"
            :radio-labels="['Sí', 'No']"
          />
          <kalio-long-text-input
            v-if="formAnswers.legal.otherInvestments.value === 'yes'"
            v-model="formAnswers.legal.otherInvestmentsDetails.value"
            name="other_investments_details"
            rules="required"
            :label="formAnswers.legal.otherInvestmentsDetails.label"
            :description="formAnswers.legal.otherInvestmentsDetails.description"
          />
          <kalio-short-text-input
            v-if="formAnswers.legal.otherInvestments.value === 'yes'"
            v-model="formAnswers.legal.funding.value"
            name="funding"
            rules="required|numeric"
            :label="formAnswers.legal.funding.label"
          />
          <kalio-short-text-input
            v-model="formAnswers.legal.spending.value"
            name="spending"
            rules="required|numeric"
            :label="formAnswers.legal.spending.label"
          />
          <kalio-short-text-input
            v-model="formAnswers.legal.savings.value"
            name="savings"
            rules="required|numeric"
            :label="formAnswers.legal.savings.label"
          />
          <kalio-short-text-input
            v-model="formAnswers.legal.runway.value"
            name="runway"
            rules="required|numeric"
            :label="formAnswers.legal.runway.label"
          />
          <kalio-radio-inputs
            v-model="formAnswers.legal.currentInvestmentRound.value"
            name="current_investment_round"
            rules="required"
            :label="formAnswers.legal.currentInvestmentRound.label"
            :values="['yes', 'no']"
            :radio-labels="['Sí', 'No']"
          />
          <kalio-long-text-input
            v-if="formAnswers.legal.currentInvestmentRound.value === 'yes'"
            v-model="formAnswers.legal.currentInvestmentRoundDetails.value"
            name="current_investment_round_details"
            rules="required"
            :label="formAnswers.legal.currentInvestmentRoundDetails.label"
          />
          <kalio-long-text-input
            v-model="formAnswers.legal.other.value"
            name="legal_other"
            :label="formAnswers.legal.other.label"
            :description="formAnswers.legal.other.description"
          />
        </kalio-form-section>

        <kalio-form-section
          section-name="Otros"
          title="Otros"
        >
          <kalio-long-text-input
            v-model="formAnswers.other.techStack.value"
            name="tech_stack"
            rules="required"
            :label="formAnswers.other.techStack.label"
          />
          <kalio-long-text-input
            v-model="formAnswers.other.alternativeIdea.value"
            name="alternative_idea"
            rules="required"
            :label="formAnswers.other.alternativeIdea.label"
          />
          <kalio-long-text-input
            v-if="dynamicStartupApplication.foundersAppliedPreviously"
            v-model="formAnswers.other.previousApplication.value"
            name="previous_application"
            :label="formAnswers.other.previousApplication.label"
          />
        </kalio-form-section>

        <kalio-form-section
          section-name="Curiosidad"
          title="Curiosidad"
        >
          <kalio-long-text-input
            v-model="formAnswers.curious.whyPV.value"
            name="why_p_v"
            rules="required"
            :label="formAnswers.curious.whyPV.label"
          />
          <kalio-long-text-input
            v-model="formAnswers.curious.acquisitionChannel.value"
            name="acquisition_channel"
            rules="required"
            :label="formAnswers.curious.acquisitionChannel.label"
          />
        </kalio-form-section>
      </div>
      <kalio-button
        label="Guardar información"
        class="mt-12 w-full sm:w-auto sm:self-center sm:px-24"
        type="submit"
        :loading="loading"
      />
    </div>
  </kalio-form-wrapper>
  <kalio-feedback-modal
    v-if="!!errorMessage"
    :title="errorMessage"
    status="error"
    accept="Entendido"
    @accept="errorMessage = null"
  />
</template>
