<script setup>
import { ref, reactive, computed, onMounted, watch } from 'vue';
import { useMutation } from '@tanstack/vue-query';
import { storeToRefs } from 'pinia';
import hotkeys from 'hotkeys-js';
import { useStartupApplicationStore } from '@/stores';
import internalStartupApplicationsApi from '@/api/internal/startup_applications.js';
import useLocalStorage from '@/hooks/useLocalStorage';
import KalioCommandBar from '@/components/shared/kalio-command-bar.vue';
import ReviewStartupApplicationQuestionBarQuestion from './startup-application-question-bar-question.vue';

const showBar = ref(false);

const props = defineProps({
  isOpen: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(['close', 'open']);

watch(() => props.isOpen, (newValue) => {
  showBar.value = newValue;
});

watch(showBar, (newValue) => {
  if (newValue) emit('open');
  else emit('close');
});

onMounted(() => {
  hotkeys.filter = () => true;

  hotkeys('shift+k', (event) => {
    event.preventDefault();
    showBar.value = !showBar.value;
  });
  hotkeys('esc', () => {
    showBar.value = false;
  });
});

const currentQuery = reactive({
  question: null,
  answer: null,
});

const startupApplicationStore = useStartupApplicationStore();
const { currentStartupApplication: startupApplication } = storeToRefs(startupApplicationStore);
const queriesKey = computed(() => `application-${startupApplication.value.id}-questions`);

const isLastQueryCurrent = ref(false);
const queries = useLocalStorage(queriesKey, []);

const { mutate, isLoading, isError, error } = useMutation(
  ['questions', startupApplication.value],
  () => internalStartupApplicationsApi.createQuestion(
    startupApplication.value.id,
    currentQuery.question,
  ),
  {
    onSuccess: (data) => {
      queries.value.unshift({ answer: data, question: currentQuery.question });
      currentQuery.question = null;
      currentQuery.value = null;
      isLastQueryCurrent.value = true;
    },
  },
);

const queriesHistory = computed(() => {
  if (isLastQueryCurrent.value) {
    return queries.value.slice(1);
  }

  return queries.value;
});

const lastQuery = computed(() => queries.value[0]);

const showSlot = computed(
  () => !!(queries.value.length > 0 || (currentQuery.question && currentQuery.answer)),
);
</script>

<template>
  <div
    class="fixed inset-0 z-30 flex size-full cursor-default items-center justify-center bg-black/50"
    :class="{ 'hidden': !showBar }"
    @click="showBar = false"
  >
    <div class="mt-12 flex h-full flex-col justify-start px-2 lg:mt-0 lg:h-3/5">
      <kalio-command-bar
        v-model="currentQuery.question"
        :loading="isLoading"
        placeholder="Escribe tu pregunta..."
        :visible-slot="showSlot"
        class="w-full lg:w-112"
        @click.stop
        @search="mutate"
        @close="showBar = false"
      >
        <div class="flex max-h-96 flex-col gap-6 overflow-y-scroll">
          <kalio-alert
            v-if="isError"
            status="alert"
            :title="error.response.data?.detail || 'Hubo un error. Intentalo de nuevo.'"
            size="medium"
            :deletable="false"
          />
          <review-startup-application-question-bar-question
            v-else-if="isLastQueryCurrent"
            :question="lastQuery.question"
            :answer="lastQuery.answer"
          />
          <div
            v-if="isLastQueryCurrent && queriesHistory.length > 0"
            class="h-px w-full bg-blue-gray-600"
          />
          <div
            v-if="queriesHistory.length > 0"
            class="flex flex-col gap-6"
          >
            <span class="font-semibold text-pv-gray">
              Preguntas recientes
            </span>
            <div class="flex flex-col gap-6">
              <review-startup-application-question-bar-question
                v-for="query in queriesHistory"
                :key="query.question"
                :question="query.question"
                :answer="query.answer"
              />
            </div>
          </div>
        </div>
      </kalio-command-bar>
    </div>
  </div>
</template>

