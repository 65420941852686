<script setup>
import { ref, reactive, computed, onMounted, inject, onUnmounted } from 'vue';
import { captureException } from '@sentry/browser';
import { storeToRefs } from 'pinia';
import { useScroll } from '@vueuse/core';
import { differenceInSeconds } from 'date-fns';
import { useMutation } from '@tanstack/vue-query';
import { INTERVIEW_STEP, RECORDING_STEP } from '@/utils/recorded_interview.js';
import { useDailyStore, useUIStore } from '@/stores';
import { screenKey } from '@/utils/keys.js';
import recordedInterviewQuestionsApi from '@/api/recorded_interview_questions.js';
import recordedInterviewsApi from '@/api/recorded_interviews.js';
import BaseDailyVideo from '@/components/shared/base-daily-video.vue';
import useActionCable from '@/hooks/actioncable.js';

const emit = defineEmits(['toggle-video', 'toggle-audio', 'leave-meeting', 'join-meeting', 'finish-interview']);
const props = defineProps({
  interviewStep: { type: String, required: true },
  recordedInterview: { type: Object, required: true },
});

const dailyStore = useDailyStore();
const { callObject, localParticipant, participants } = storeToRefs(dailyStore);

const dates = reactive({
  recordingStartedAt: null,
  lastQuestionStartedAt: null,
});

const recordedInterviewQuestion = ref(null);
const nextQuestionSubmitDisabled = ref(false);
const recordingStep = ref(RECORDING_STEP.notStarted);
const NEXT_QUESTION_BUTTON_DELAY_MS = 5000;

const TIMER_HIDDEN_DURATION = 45;
const TIMER_DURATION = 45;

const questionTimer = ref(null);
const timerInterval = ref(null);
const isTransitioning = ref(false);

const showTimer = computed(() => questionTimer.value > TIMER_HIDDEN_DURATION);

function startQuestionTimer() {
  if (timerInterval.value) clearInterval(timerInterval.value);
  questionTimer.value = 0;
  isTransitioning.value = false;
  timerInterval.value = setInterval(() => {
    questionTimer.value += 1;

    if (questionTimer.value > TIMER_DURATION + TIMER_HIDDEN_DURATION) {
      clearInterval(timerInterval.value);
      isTransitioning.value = true;
      setTimeout(handleNextQuestion, 2 * 1000); // eslint-disable-line no-use-before-define
    }
  }, 1000);
}

const channel = useActionCable(
  { channel: 'RecordedInterviewChannel', id: props.recordedInterview.id },
  {
    received(event) {
      if (event.kind === 'question') {
        recordedInterviewQuestion.value = {
          ...event.data,
          start: differenceInSeconds(new Date(), dates.recordingStartedAt),
        };
        dates.lastQuestionStartedAt = new Date();
        nextQuestionSubmitDisabled.value = true;
        startQuestionTimer();
        setTimeout(() => {
          nextQuestionSubmitDisabled.value = false;
        }, NEXT_QUESTION_BUTTON_DELAY_MS);
      } else if (event.kind === 'recording_started') {
        recordingStep.value = RECORDING_STEP.recording;
      } else if (event.kind === 'recording_finished') {
        recordingStep.value = RECORDING_STEP.finished;
      }
    },
  },
);

const uiStore = useUIStore();
const {
  mutate: startRecording,
  isLoading: isStartRecordingLoading,
} = useMutation({
  mutationFn: () => recordedInterviewsApi.startRecording(props.recordedInterview.id),
  onError: (error) => {
    captureException(error);

    uiStore.toast({
      message: 'No pudimos comenzar la grabación.',
      type: 'error',
      position: 'top',
      persist: true,
    });
  },
  onSuccess: () => {
    channel.send({ kind: 'recording_started' });
  },
});

const { mutate: stopRecording, isLoading: isStopRecordingLoading } = useMutation({
  mutationFn: () => recordedInterviewsApi.stopRecording(props.recordedInterview.id),
  onSuccess: () => {
    channel.send({ kind: 'recording_finished' });
    emit('finish-interview');
  },
});

onMounted(() => {
  callObject.value.on('recording-started', () => {
    dates.recordingStartedAt = new Date();
    dates.lastQuestionStartedAt = new Date();
  });

  if (localParticipant.value.owner) {
    startRecording();
  }
});

const { mutate: saveQuestion, isLoading: postQuestionLoading } = useMutation({
  mutationFn: () => recordedInterviewQuestionsApi.create(
    props.recordedInterview.id,
    {
      ...recordedInterviewQuestion.value,
      duration: differenceInSeconds(new Date(), dates.lastQuestionStartedAt),
    },
  ),
  onSuccess: ({ nextQuestion }) => {
    if (nextQuestion) {
      channel.send({ kind: 'question', data: nextQuestion });
    } else {
      stopRecording();
    }
  },
});

const isFetchingFirstQuestion = ref(false);
function fetchFirstQuestion() {
  isFetchingFirstQuestion.value = true;
  recordedInterviewQuestionsApi.new(props.recordedInterview.id).then((data) => {
    channel.send({ kind: 'question', data });
    isFetchingFirstQuestion.value = false;
  });
}

function handleNextQuestion() {
  if (recordedInterviewQuestion.value) {
    saveQuestion();
  } else {
    fetchFirstQuestion();
  }
}

const scrollableContainer = ref(null);
const { y } = useScroll(scrollableContainer);

const loading = computed(
  () => isStartRecordingLoading.value ||
    isFetchingFirstQuestion.value ||
    postQuestionLoading.value ||
    isStopRecordingLoading.value,
);
const question = computed(() => recordedInterviewQuestion.value?.question);

const screen = inject(screenKey);

const nextButtonDisabled = computed(
  () => loading.value || isTransitioning.value ||
    recordingStep.value !== RECORDING_STEP.recording || nextQuestionSubmitDisabled.value,
);

onUnmounted(() => {
  if (timerInterval.value) clearInterval(timerInterval.value);
});

const PROGRESS_CIRCLE_RADIUS = 20;
const progressCircleStyle = computed(() => {
  const circumference = 2 * Math.PI * PROGRESS_CIRCLE_RADIUS;
  const progress = 1 - (questionTimer.value - TIMER_HIDDEN_DURATION) / TIMER_DURATION;

  return {
    strokeDasharray: `${circumference} ${circumference}`,
    strokeDashoffset: circumference * (1 - progress),
  };
});
</script>

<template>
  <div class="flex h-96 w-full flex-col gap-4 md:flex-row">
    <div
      class="relative flex size-full grow flex-col rounded-lg border bg-pv-blue-900 px-4 py-6 md:px-8 md:py-12"
      :class="recordingStep === RECORDING_STEP.recording ? 'border-red-500' : 'border-transparent'"
    >
      <div
        v-if="recordingStep === RECORDING_STEP.recording"
        class="absolute left-2 top-2 flex flex-row items-center space-x-2 sm:left-auto sm:right-4"
      >
        <span class="text-xs text-blue-gray-400 sm:text-sm">
          Grabando
        </span>
        <div class="relative size-2 sm:size-3">
          <div class="absolute inset-0 z-30 rounded-full bg-red-500" />
          <div class="absolute inset-0 z-40  animate-ping rounded-full bg-red-500" />
        </div>
      </div>
      <div class="mb-4 flex flex-row">
        <div class="mr-4 hidden self-start rounded-full border border-pv-yellow/20 bg-pv-blue-800 p-3 sm:flex">
          <inline-svg
            :src="require('assets/images/icons/brand/platanus-isotype.svg')"
            class="size-6 shrink-0 fill-current text-pv-yellow"
          />
        </div>
        <p
          v-if="interviewStep === INTERVIEW_STEP.finished"
          class="mt-3 text-sm sm:text-base"
        >
          Con eso termina la entrevista. Muchas gracias por su tiempo.
          <br>
          <br>
          Guardaremos la grabación y será revisada por alguien del equipo.
        </p>
        <p
          v-else-if="question"
          class="mt-3 text-sm sm:text-base"
        >
          {{ question }}
        </p>
        <p
          v-else
          class="mt-3 whitespace-pre-line text-sm sm:text-base"
        >
          A continuación les haremos una serie de preguntas.
          <br>
          <br>
          Cada pregunta aparecerá en pantalla y deben empezar a responderla de inmediato.
          <br>
          <br>
          Cuando hayan terminado de responder, alguno debe hacer click en "Siguiente pregunta".
          Si demoran mucho, las preguntas avanzarán automáticamente.
          <br>
          <br>
          Les recomendamos que se pongan de acuerdo para que una sola persona sea la que pase las preguntas.
        </p>
      </div>
      <div class="mt-auto flex items-center gap-4 self-center sm:self-end">
        <p
          v-show="isTransitioning"
          class="text-sm sm:text-base"
        >
          Pasando a la siguiente pregunta...
        </p>
        <span
          v-show="!isTransitioning && showTimer && question"
          class="relative inline-flex size-8 items-center justify-center"
        >
          <svg
            class="absolute size-8"
            viewBox="0 0 44 44"
          >
            <circle
              cx="22"
              cy="22"
              :r="PROGRESS_CIRCLE_RADIUS"
              fill="none"
              stroke="currentColor"
              stroke-width="1.5"
              class="origin-center -rotate-90 transition-all duration-1000 ease-linear"
              :style="progressCircleStyle"
            />
          </svg>
          <span class="z-10 text-center text-xs">
            {{ TIMER_DURATION - (questionTimer - TIMER_HIDDEN_DURATION) }}
          </span>
        </span>
        <template v-if="screen.isMobile">
          <button
            v-if="interviewStep === INTERVIEW_STEP.interview"
            :disabled="nextButtonDisabled"
            :loading="loading"
            class="flex flex-row items-center text-sm text-pv-yellow"
            @click="handleNextQuestion"
          >
            <span>
              {{ question ? 'Siguiente pregunta' : 'Siguiente' }}
            </span>
            <inline-svg
              :src="require('assets/images/icons/long-arrow.svg')"
              class="ml-2 h-3 fill-current"
            />
          </button>
          <a
            v-else-if="interviewStep === INTERVIEW_STEP.finished"
            class="text-sm text-pv-yellow"
            href="/apply"
          >
            Volver al home
          </a>
        </template>
        <template v-else>
          <kalio-button
            v-if="interviewStep === INTERVIEW_STEP.interview"
            :label="question ? 'Siguiente pregunta' : 'Siguiente'"
            class="px-12"
            :disabled="nextButtonDisabled"
            :loading="loading"
            @click="handleNextQuestion"
          />
          <kalio-button
            v-else-if="interviewStep === INTERVIEW_STEP.finished"
            label="Volver al home"
            class="px-12"
            href="/apply"
          />
        </template>
      </div>
    </div>
    <div class="relative sm:h-full">
      <button
        v-if="participants.length > 4 && y > 0"
        class="group absolute left-0 top-0 z-20 hidden w-full items-center justify-center bg-black/30 p-4 sm:flex"
      >
        <!-- animation removes rotation, so we need to apply animation to a parent -->
        <div class="group-hover:animate-bounce">
          <inline-svg
            :src="require('assets/images/icons/chevron-double-down.svg')"
            class="size-4 rotate-180 stroke-current text-pv-gray"
          />
        </div>
      </button>
      <div
        ref="scrollableContainer"
        class="flex size-full shrink-0 flex-row overflow-scroll rounded-lg border border-blue-gray-700 bg-pv-blue-800 sm:w-44 sm:flex-col"
      >
        <base-daily-video
          v-for="participant in participants"
          :key="participant.key"
          class="h-28 w-48 border-b-2 border-blue-gray-700 last:border-b-0 sm:h-24 sm:w-full"
          :participant="participant"
          @toggle-video="$emit('toggle-video')"
          @toggle-audio="$emit('toggle-audio')"
          @leave-meeting="$emit('leave-meeting')"
        />
      </div>
      <button
        v-if="participants.length > 4 && y === 0"
        class="group absolute bottom-0 left-0 z-20 hidden w-full items-center justify-center bg-black/30 p-4 sm:flex"
      >
        <inline-svg
          :src="require('assets/images/icons/chevron-double-down.svg')"
          class="size-4 stroke-current text-pv-gray group-hover:animate-bounce"
        />
      </button>
    </div>
  </div>
</template>
