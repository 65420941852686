<script setup>
import { storeToRefs } from 'pinia';
import { useStartupApplicationStore } from '@/stores';
import KalioTextAnswer from '@/components/form/kalio-text-answer.vue';
import useFormAnswers from '@/hooks/form_answers';

const FORM_ANSWERS_PATHS = [
  'idea.why',
  'idea.insight',
  'idea.whyFirst',
  'idea.timeWorkingFulltime',
  'idea.users',
  'idea.competition',
  'idea.money',
  'idea.opportunitySize',
  'idea.money100k',
  'idea.money1m',
  'idea.money10m',
];

const startupApplicationStore = useStartupApplicationStore();
const { currentStartupApplication: startupApplication } = storeToRefs(startupApplicationStore);

const { formAnswers } = useFormAnswers(FORM_ANSWERS_PATHS, startupApplication.value);
</script>

<template>
  <div class="flex flex-col space-y-4">
    <kalio-text-answer
      :label="$t('startupApplication.longDescription')"
      :answer="startupApplication.longDescription"
    />
    <kalio-text-answer
      v-for="(item, index) in formAnswers"
      :key="index"
      :label="item.label"
      :answer="item.answer"
    />
  </div>
</template>
