<script setup>
import { ref, watch, computed, toRef } from 'vue';
import { useField } from 'vee-validate';
import isMobilePhone from 'validator/src/lib/isMobilePhone';
import KalioSpinner from '@/components/shared/kalio-spinner.vue';
import { COUNTRIES } from '@/utils/countries.js';
import useValidatePhone from '@/hooks/validate-phone.js';

defineEmits(['update:modelValue']);
const props = defineProps({
  name: { type: String, required: true },
  label: { type: String, default: '' },
  required: { type: Boolean, default: false },
  description: { type: String, default: '' },
  modelValue: { type: [Number, String], default: '' },
  startEmpty: { type: Boolean, default: false },
  value: { type: [Number, String], default: null },
  countryHint: { type: Object, default: () => ({ code: 'CL' }) },
  error: { type: String, default: null },
  size: {
    type: String,
    default: 'base',
    validator: (value) => ['small', 'base'].includes(value),
  },
  performValidation: { type: Boolean, default: false },
});

function filteredCountries(search) {
  return COUNTRIES.filter(
    (country) =>
      country.name.toLowerCase().includes(search.toLowerCase()) ||
      country.code.toLowerCase().includes(search.toLowerCase()),
  );
}

const selectOpen = ref(false);
const selectedCountry = ref(null);
const countryFilter = ref('');
const countryFilterInputRef = ref(null);

const countries = computed(() => {
  if (countryFilter.value) {
    return filteredCountries(countryFilter.value);
  }

  return COUNTRIES;
});

function toggleSelect() {
  selectOpen.value = !selectOpen.value;
  countryFilter.value = '';
  if (selectOpen.value) {
    countryFilterInputRef.value.focus();
  }
}

const phoneRule = computed(() => (selectedCountry.value ?
  `phone:${selectedCountry.value.lang}-${selectedCountry.value.key}` : 'phone'),
);
const rules = computed(() => `${props.required ? 'required' : ''}|${phoneRule.value}`);
const {
  value: textInputValue,
  errorMessage,
  validate,
  setErrors,
  handleBlur,
  meta,
} = useField(props.name, rules, { validateOnValueUpdate: false });

const textInputRef = ref(null);

function selectCountry(country, focus = true) {
  selectOpen.value = false;
  if (selectedCountry.value && textInputValue.value.includes(`+${selectedCountry.value.code}`)) {
    textInputValue.value = textInputValue.value.replace(`+${selectedCountry.value.code}`, '');
  }
  textInputValue.value = `+${country.code}${textInputValue.value || ''}`;
  selectedCountry.value = country;

  if (focus) {
    textInputRef.value.focus();
  }
}

function setFromCountryHint() {
  const code = props.countryHint?.code?.toUpperCase();
  const countryIndex = COUNTRIES.findIndex(country => country.key === code);
  if (countryIndex === -1) {
    const chileanIndex = COUNTRIES.findIndex(country => country.key === 'CL');
    selectCountry(COUNTRIES[chileanIndex], false);
  } else {
    selectCountry(COUNTRIES[countryIndex], false);
  }
}

function setInitialVariables() {
  if (props.startEmpty) return;

  let phoneNumber = props.modelValue || props.value || '';
  phoneNumber = phoneNumber.replaceAll('+', '').replaceAll(' ', '');

  if (phoneNumber === '' && props.countryHint) {
    setFromCountryHint();

    return;
  }

  let initialCountryCodeIndex = COUNTRIES.findIndex(
    country => phoneNumber && phoneNumber.startsWith(country.code),
  );

  if (initialCountryCodeIndex === -1) {
    initialCountryCodeIndex = 0;
  }

  const country = COUNTRIES[initialCountryCodeIndex];

  const code = country.code;
  phoneNumber = phoneNumber.replace(code, '');
  textInputValue.value = `+${code}${phoneNumber}`;
  selectedCountry.value = country;
}

setInitialVariables();

watch(textInputValue, () => {
  if (textInputValue.value?.includes(' ')) {
    textInputValue.value = textInputValue.value.replaceAll(' ', '');
  }

  if (textInputRef.value && textInputRef.value.contains(document.activeElement)) {
    validate().then(() => {
      if (!props.required && !textInputValue.value) {
        setErrors([]);
      }
    });
  }

  const countryGuesses = COUNTRIES.filter((country) => textInputValue.value.startsWith(`+${country.code}`));
  if (countryGuesses.length === 1) {
    selectedCountry.value = countryGuesses[0];
  }
});

const countryHint = toRef(props, 'countryHint');
watch(countryHint, () => {
  if (props.startEmpty || props.modelValue !== '') return;

  setFromCountryHint();
}, { immediate: true });

function handleFocusOut(event) {
  if (!event.relatedTarget) {
    selectOpen.value = false;
  }
}

const isMobilePhoneStrict = computed(() => {
  if (!selectedCountry.value) {
    return false;
  }

  try {
    return isMobilePhone(
      textInputValue.value,
      `${selectedCountry.value.lang}-${selectedCountry.value.key}`,
      { strictMode: true },
    );
  } catch {
    return isMobilePhone(textInputValue.value);
  }
});

const enabled = toRef(props, 'performValidation');
const {
  validatePhone,
  isValidatingPhone,
  isPhoneValid,
  resetIsPhoneValid,
} = useValidatePhone(enabled);

const isInputFocused = ref(false);

function onBlur(event) {
  handleBlur(event);
  isInputFocused.value = false;
  if (
    meta.touched &&
          props.performValidation &&
          isMobilePhoneStrict.value &&
          !isPhoneValid.value
  ) validatePhone(textInputValue.value);
}
watch(textInputValue, resetIsPhoneValid);

const sizeClasses = computed(() => (
  props.size === 'base' ? 'h-11' : 'h-9'
));

</script>

<template>
  <kalio-base-input
    :label="label"
    :description="description"
    :required="required"
    :size="size"
  >
    <template #input>
      <div class="relative flex w-full flex-row items-start gap-1">
        <button
          class="h-11 rounded-md border-2 border-blue-gray-700 bg-pv-blue-900"
          :class="size === 'base' && 'py-1'"
          type="button"
          @click="toggleSelect"
          @focusout="handleFocusOut"
        >
          <input
            ref="countryFilterInputRef"
            v-model="countryFilter"
            class="absolute size-0"
            name="`${name}-country-filter`"
          >
          <div class="flex flex-row items-center px-2">
            <span
              v-if="selectedCountry"
              class="text-2xl"
            >
              {{ selectedCountry.emoji }}
            </span>
            <inline-svg
              :src="require('assets/images/icons/toggle-arrow.svg')"
              class="ml-2 size-3 fill-current text-pv-gray"
              :class="{ 'rotate-180': !selectOpen }"
            />
          </div>
        </button>
        <div
          v-if="selectOpen"
          class="absolute top-12 z-30 h-48 w-64 overflow-scroll rounded-md border border-blue-gray-700 bg-pv-blue-900 py-2 shadow sm:h-64 sm:w-80"
        >
          <button
            v-for="country in countries"
            :key="country.name"
            class="flex w-full flex-row items-center space-x-2 px-2 py-0.5 hover:bg-pv-blue-700"
            type="button"
            @click="selectCountry(country)"
          >
            <span class="text-xl">
              {{ country.emoji }}
            </span>
            <span class="text-ellipsis whitespace-nowrap">
              {{ country.name }}
            </span>
            <span class="text-blue-gray-400">
              +{{ country.code }}
            </span>
          </button>
        </div>
        <div class="relative flex grow flex-col">
          <div
            class="flex w-full flex-row items-center space-x-3 rounded-md border-2 bg-pv-blue-900 p-2 pr-4"
            :class="[
              errorMessage || error
                ? 'border-red-600'
                : 'border-blue-gray-700 focus-within:border-pv-light-blue focus-within:ring-pv-light-blue',
              sizeClasses
            ]"
          >
            <input
              ref="textInputRef"
              v-model="textInputValue"
              class="w-full bg-transparent text-blue-gray-50 outline-none"
              :name="`${name}-text-input`"
              @blur="onBlur"
              @focus="isInputFocused = true"
            >
            <kalio-spinner
              v-if="isValidatingPhone"
              class="size-3"
            />
          </div>
          <span
            v-if="errorMessage || error"
            class="text-sm text-red-600"
          >
            {{ errorMessage || error }}
          </span>
          <span
            v-else-if="isValidatingPhone"
            class="italic text-pv-gray"
          >
            {{ $t('form.phone.validating') }}
          </span>
          <div
            v-else-if="isMobilePhoneStrict && isPhoneValid && performValidation"
            class="mt-1 flex flex-row items-center space-x-1.5 self-start"
          >
            <inline-svg
              :src="require('assets/images/icons/outline/check-circle.svg')"
              class="size-4 stroke-current text-green-400"
            />
            <span class="text-sm text-green-400">
              {{ $t('form.phone.valid') }}
            </span>
          </div>
          <VTooltip
            v-else-if="meta.touched && !isInputFocused && performValidation"
            placement="bottom"
            class="mt-1 flex cursor-pointer flex-row items-center space-x-1.5 self-start"
          >
            <inline-svg
              :src="require('assets/images/icons/outline/warning.svg')"
              class="size-4 stroke-current text-yellow-400"
            />
            <span class="text-sm text-yellow-400">
              {{ $t('form.phone.invalid.warning') }}
            </span>
            <template #popper>
              <p class="max-w-sm text-yellow-500">
                {{ $t('form.phone.invalid.tooltip') }}
              </p>
            </template>
          </VTooltip>
        </div>
      </div>
    </template>
  </kalio-base-input>
</template>
